import api from '../services/api'
import Helper from './helper'

class ColorService {
  get(p, s) {
    return api.get(`/c?${Helper.getPageQuery(p, s)}`)
  }

  create(row) {
    return api.post('/c', row)
  }

  update(row) {
    return api.put('/c/' + row.id, row)
  }

  delete(row) {
    return api.delete('/c/' + row.id)
  }
}

export default new ColorService()
